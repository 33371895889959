// src/components/Articles.js
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchArticles } from '../store/actions';

const Articles = ({ fetchArticles, articles }) => {
  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  useEffect(() => {
    console.log('Articles:', articles);
  }, [articles]);

  // Render your component here
};

const mapStateToProps = (state) => {
  return {
    articles: state.articles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticles: () => dispatch(fetchArticles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
