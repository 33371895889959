import React from 'react';

const Contact = () => (
  <section className="mt-8 mb-12 bg-dark-secondary p-6 rounded-lg shadow-lg">
    <h2 className="text-2xl font-bold text-light-accent">Contact</h2>
    <p className="text-light-accent">
      Connect with me on{' '}
      <a
        href="https://www.linkedin.com/in/adg1"
        className="text-blue-500 hover:text-blue-700"
        target="_blank"
        rel="noopener noreferrer"
      >
        LinkedIn
      </a>
      , or feel free to reach out via email at{' '}
      <a
        href="mailto:austindouglasgriffith@gmail.com"
        className="text-blue-500 hover:text-blue-700"
      >
        austindouglasgriffith@gmail.com
      </a>
      , or by phone at{' '}
      <a href="tel:+13523452670" className="text-blue-500 hover:text-blue-700">
        (352) 345-2670
      </a>
      .
    </p>
  </section>
);

export default Contact;
