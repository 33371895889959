// Header.js
import React from 'react';

const Header = () => (
  <header className="bg-gradient-to-r from-dark-start to-dark-end text-white py-6">
    <h1 className="text-4xl font-bold text-center">Austin Griffith</h1>
  </header>
);

export default Header;
