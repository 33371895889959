// WorkExperience.js
import React from 'react';
import LearnMore from './LearnMore';

const WorkExperience = () => (
  <div className="section-container">
    <section className="bg-dark-secondary p-6 rounded-lg shadow-lg flex flex-col sm:flex-row items-center">
      <div className="flex-grow">
        <h2 className="text-2xl font-bold text-light-accent">Work Experience</h2>
        <p className="text-light-accent mt-2">
          I am currently a pivotal member of the team at {' '}
          <a
            href="https://sureviewsystems.com/"
            className="text-blue-500 hover:text-blue-700"
            target="_blank"
            rel="noopener noreferrer"
          >
            SureView Systems
          </a>
          , a frontrunner in software security solutions. My role is multifaceted, spanning across development, sales, product management, engineering, and leading the Research & Development department. Here’s a glimpse into my diverse contributions at SureView Systems:
        </p>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-4 flex-shrink-0">
        <img
          src="Resources/svslogo.png"
          alt="SureView Logo"
          style={{ width: '12rem', height: '6rem', objectFit: 'contain' }}
        />
      </div>
    </section>
    <LearnMore>
      <p className="text-light-accent ml-4 -mt-2">
        Here's a brief breakdown of some my contributions to SureView Systems:
      </p>
      <ul className="list-disc list-inside text-light-accent mt-2 ml-6 mb-2">
        <li>
          <strong>Development:{' '}</strong>
          Orchestrated the creation of multiple tools enhancing both internal operations and customer interactions. Notable projects include an RFP Question Completion Tool that uses vector embeddings and a Large Language Model to automate proposal preparations, and our innovative Virtual Operator, an AI-driven agent autonomously processing security alarms via the SureView API.
          I also created a comprehensive SOW tracking system utilized by all employees to monitor development items efficiently.
        </li>
        <li>
          <strong>Sales:{' '}</strong>
          I serve as the principal contact for new leads, guiding them through the entire sales cycle—from initial introductions, demonstrations, sign-ups, to trial support—ensuring a seamless transition to satisfied, paying customers.
        </li>
        <li>
          <strong>Product Management{' '}</strong>
          Advised the C-suite on the potential and costs associated with emerging technologies like Large Language Models and Video Analytics, aligning business objectives with technical capabilities.
        </li>
        <li>
          <strong>Engineering:{' '}</strong>
          Ensured exemplary support for existing customers facing technical challenges, applying integration updates and resolving tickets promptly to maintain a high level of customer satisfaction.
        </li>
      </ul>
      <div className="mt-4 text-center">
        <a
          href="Austin_Griffith_Resume.pdf"  // replace with the actual path to your resume
          className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          download="Austin_Griffith_Resume.pdf"  // replace with the desired name of the downloaded file
        >
          Download My Resume
        </a>
      </div>
    </LearnMore>
  </div>
);

export default WorkExperience;
