// LearnMore.js
import React, { useState } from 'react';
import './LearnMore.css';

const LearnMore = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(true);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    setAnimationFinished(false);
  };

  const handleTransitionEnd = () => {
    setAnimationFinished(true);
  };

  const buttonClass = animationFinished
    ? (isExpanded
      ? "w-full bg-dark-accent p-3 -mt-1 pl-4 text-left text-light-accent font-semibold"
      : "w-full bg-dark-accent p-3 -mt-1 pl-4 rounded-b-lg text-left text-light-accent font-semibold")
    : "w-full bg-dark-accent p-3 -mt-1 pl-4 text-left text-light-accent font-semibold";

  return (
    <>
      <button className={buttonClass} onClick={toggleExpanded}>
        {isExpanded ? 'Collapse' : 'Learn More'}
      </button>
      <div
        className={`learn-more-content ${isExpanded ? 'expanded' : ''}`}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="bg-dark-accent p-3 rounded-b-lg text-light-accent">
          {children}
        </div>
      </div>
    </>
  );
};

export default LearnMore;
