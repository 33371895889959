// src/store/reducers.js
import { FETCH_ARTICLES_REQUEST, FETCH_ARTICLES_SUCCESS, FETCH_ARTICLES_FAILURE } from './actionTypes';

const initialState = {
  articles: [],
  loading: false,
  error: null,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLES_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_ARTICLES_SUCCESS:
      return { ...state, articles: action.payload, loading: false };
    case FETCH_ARTICLES_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default rootReducer;