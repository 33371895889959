// Education.js
import React from 'react';

const Education = () => (
    <div className="section-container">
      <section className="mt-8 bg-dark-secondary p-6 rounded-lg shadow-lg flex flex-col sm:flex-row items-center">
        <div className="flex-grow">
          <h2 className="text-2xl font-bold text-light-accent">Education</h2>
          <p className="text-light-accent">
            Graduated from the University of South Florida with a Bachelor of Science
            in Physics
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-4 flex-shrink-0">
          <img
            src="Resources/usflogo.png"
            alt="Education Logo"
            style={{ width: '8rem', height: '5rem', objectFit: 'contain' }}
          />
        </div>
      </section>
    </div>
  );

export default Education;
