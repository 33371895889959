// src/store/actions.js
import { FETCH_ARTICLES_REQUEST, FETCH_ARTICLES_SUCCESS, FETCH_ARTICLES_FAILURE } from './actionTypes';

export const fetchArticlesRequest = () => {
  return {
    type: FETCH_ARTICLES_REQUEST,
  };
};

export const fetchArticlesSuccess = (articles) => {
  return {
    type: FETCH_ARTICLES_SUCCESS,
    payload: articles,
  };
};

export const fetchArticlesFailure = (error) => {
  return {
    type: FETCH_ARTICLES_FAILURE,
    payload: error,
  };
};

export const fetchArticles = () => {
  return async (dispatch) => {
    dispatch(fetchArticlesRequest());
    try {
      const response = await fetch('/api/articles');
      const data = await response.json();
      dispatch(fetchArticlesSuccess(data));
    } catch (error) {
      dispatch(fetchArticlesFailure(error.message));
    }
  };
};