// Publications.js
import React from 'react';
import LearnMore from './LearnMore';

const Publications = () => (
  <div className="section-container">
    <section className="mt-8 bg-dark-secondary p-6 rounded-lg shadow-lg flex flex-col sm:flex-row items-center">
      <div className="flex-grow">
        <h2 className="text-2xl font-bold text-light-accent">Publications</h2>
        <p className="text-light-accent">
          Co-authored two peer-reviewed articles in Physical Review E on computational
          soft matter research, focusing on the jamming phase transition. You can find them{' '}
          <a
            href="https://journals.aps.org/search/results?sort=relevance&clauses=%5B%7B%22operator%22%3A%22AND%22%2C%22field%22%3A%22author%22%2C%22value%22%3A%22Austin+D+Griffith%22%7D%5D"
            className="text-blue-500 hover:text-blue-700"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </div>
      <div className="mr-4 mt-4 sm:mt-0 sm:ml-4 flex-shrink-0">
        <img
          src="Resources/apslogo.png"
          alt="APS Logo"
          style={{ width: '7rem', height: '5rem', objectFit: 'contain' }}
        />
      </div>
    </section>
    <LearnMore>
      <p className="text-light-accent -mt-2 ml-4">
        A brief summary of the two published papers:
      </p>
      <ul className="list-disc list-inside text-light-accent mt-2 ml-6 mb-2">
        <li>
          <strong><a
            href="https://journals.aps.org/pre/abstract/10.1103/PhysRevE.98.042910"
            className="text-blue-500 hover:text-blue-700"
            target="_blank"
            rel="noopener noreferrer"
          >
            Paper 1
          </a></strong> Studied maximally dense lattice packings of tangent-disk trimers with fixed bond angles and analyzed the jamming phase transition. Explored the effects of bond topologies and identified degenerate, lower-symmetry versions of the densest packings.
        </li>
        <li>
          <strong><a
            href="https://journals.aps.org/pre/abstract/10.1103/PhysRevE.100.022903"
            className="text-blue-500 hover:text-blue-700"
            target="_blank"
            rel="noopener noreferrer"
          >
            Paper 2
          </a></strong> Investigated maximally dense packings of tangent-sphere trimers with fixed bond angles in 3D. Analyzed the formation of periodic structures composed of close-packed bilayers or trilayers of triangular-lattice planes, and examined the variations of jamming density with bond angles.

        </li>
      </ul>
      <img
        src="Resources/labPic.jpeg"
        alt="USF Lab Pic"
        className="mx-auto mt-6 mb-4"
        style={{ width: '95%', objectFit: 'contain' }}
      />
    </LearnMore>
  </div>
);

export default Publications;
