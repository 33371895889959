// Projects.js
import React from 'react';
import LearnMore from './LearnMore';

const Projects = () => (
  <div className="section-container">
    <section className="mt-8 bg-dark-secondary p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-light-accent mb-6">Projects</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="bg-gray-800 p-4 rounded-lg">
          <div className="relative" style={{ paddingBottom: '56.25%' }}>
            <iframe
              className="absolute top-0 left-0 w-full h-full rounded-lg"
              src="https://www.youtube.com/embed/WMHCviLeAak"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <h3 className="text-xl font-bold text-light-accent mt-4">
            <a
              href="https://www.stinergy.com/"
              className="text-blue-500 hover:text-blue-700"
              target="_blank"
              rel="noopener noreferrer"
            >
              Satellite Tracking App with Augmented Reality
            </a>
          </h3>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg">
          <img
            src="Resources/fitimage.png"
            alt="FitBit Apps"
            className="w-full h-52 object-cover mb-4 rounded-lg"
          />
          <h3 className="text-xl font-bold text-light-accent">
            <a
              href="https://gallery.fitbit.com/developer/a70a3bcd-dedf-4f57-a595-2b7a6dc1f12e"
              className="text-blue-500 hover:text-blue-700"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apps and Watch-faces for FitBit Smart Watches
            </a>
          </h3>
        </div>
      </div>
    </section>
    <LearnMore>
      <div className="text-light-accent -mt-2 ml-4 mb-2">
        <p>
          Satellite Chasers is an app that tracks satellites in real-time using data from the U.S. Space Command. It features Augmented Reality, allowing users to see satellites through their phone's camera. Users can also set reminders, view satellite information, and compete on a leaderboard.
        </p>
        <p className="mt-4">
          I have also developed a variety of apps and watch-faces for FitBit Smart Watches, including games like Minesweeper, Mancala, Checkers, and Solitaire. These apps provide users with engaging ways to interact with their smartwatches.
        </p>
      </div>
    </LearnMore>
  </div>
);

export default Projects;
