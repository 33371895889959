import React, { useState } from 'react';
import Header from './components/Header';
import Education from './components/Education';
import Publications from './components/Publications';
import Projects from './components/Projects';
import WorkExperience from './components/WorkExperience';
import Contact from './components/Contact';
import Articles from './components/Articles';
import './index.css';

const App = () => {
  return (
    <div>
      <Header />
      <div className="App max-w-4xl mx-auto px-4 py-8 bg-dark-main min-h-screen">
        <main>
          <WorkExperience />
          <Projects />
          <Publications />
          <Education />
          <Contact />
          {/* <Articles /> */}
        </main>
      </div>
    </div>
  );
};

export default App;